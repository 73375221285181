@import "node_modules/reset.css";
@import "src/include";
@import "./scale";

// @import "../assets/fonts/style.css";
// style.css imported manual in /pages/_app.js;

* {
  box-sizing: border-box;
}

body {
  overflow: hidden scroll;

  min-height: 100vh;

  font-family: Poppins, sans-serif;
  font-feature-settings: 'lnum';
  font-weight: 300;
  color: #101053;

  background-color: $colorBackground;

  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

img {
  max-width: 100%;
  max-height: 100%;
}

button {
  background-color: transparent;

  &:disabled {
    filter: grayscale(100%);
  }

  ::-moz-focus-inner {
    border: 0;
  }
}

.hideOnPhone {
  @media #{$phone} {
    display: none;
  }
}

.hideOnTabletAndDesktop {
  @media #{$tablet-and-desktop} {
    display: none;
  }
}

.visibleOnDesktopAndTablet {
  @media #{$phone} {
    display: none;
  }
}

.visibleOnPhone {
  @media #{$tablet-and-desktop} {
    display: none;
  }
}

.fade-enter {
  opacity: 0;
}

.fade-enter-active {
  opacity: 1;
}

.fade-exit {
  opacity: 1;
}

.fade-exit-active {
  opacity: 0;
}

.fade-enter-active,
.fade-exit-active {
  transition: opacity 500ms;
}
