@import 'styles/media';

$colorBackground: #FFF;

%container-full {
  $width: 1810px;
  $paddingHorizontal: 15px;

  box-sizing: border-box;
  max-width: $width + ($paddingHorizontal * 2);
  margin-right: auto;
  margin-left: auto;
  padding: 0 $paddingHorizontal;
}

%container {
  $width: 1170px;
  $paddingHorizontal: 15px;

  box-sizing: border-box;
  max-width: $width + ($paddingHorizontal * 2);
  margin-right: auto;
  margin-left: auto;
  padding: 0 $paddingHorizontal;
}
